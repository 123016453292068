<template>
  <div
    v-if="
      (carbonFootprints || procurementsTracking || (chart && chartTypeSelector)) &&
      !scope2Comparisson
    "
    class="button-filters chart"
  >
    <span>Chart</span>
    <div
      v-if="carbonFootprints || procurementsTracking"
      :class="['icon filter-icon circled tooltip stack', { 'icon-active': chartType == 'stack' }]"
      @click="urlModifier('stack')"
    >
      <inline-svg :src="require('images/icons/bar_chart.svg')"></inline-svg>
      <span class="tooltiptext">Stack type</span>
    </div>

    <div
      v-if="
        !(byCategories && !estate) &&
        ((carbonFootprints && (!byScope || estate)) || procurementsTracking)
      "
      :class="['icon filter-icon circled tooltip pie', { 'icon-active': chartType == 'pie' }]"
      @click="urlModifier('pie')"
    >
      <inline-svg :src="require('images/icons/pie_chart.svg')"></inline-svg>
      <span class="tooltiptext">Pie type</span>
    </div>

    <div
      v-if="chart && chartTypeSelector"
      :class="['icon filter-icon tooltip circled', { 'icon-active': chartType == 'column' }]"
      @click="urlModifier('column')"
    >
      <inline-svg :src="require('images/icons/bar_chart.svg')"></inline-svg>
      <span class="tooltiptext">Columns type</span>
    </div>

    <div
      v-if="chart && chartTypeSelector"
      :class="['icon filter-icon tooltip circled', { 'icon-active': chartType == 'line' }]"
      @click="urlModifier('line')"
    >
      <inline-svg :src="require('images/icons/line_chart.svg')"></inline-svg>
      <span class="tooltiptext">Line type</span>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  props: {
    defaultParams: Object,
    carbonFootprints: {
      type: Boolean,
      default: false,
    },
    procurementsTracking: {
      type: Boolean,
      default: false,
    },
    chart: {
      type: Boolean,
      default: true,
    },
    chartTypeSelector: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      chartType: null,
      resultsBy: null,
      groupBy: null,
    };
  },
  methods: {
    urlModifier: function (value) {
      let url = new URL(window.location.href);

      url.searchParams.delete('category');
      url.searchParams.delete('item_type');

      if (this.resultsBy == 'all' || this.resultsBy == 'supplier') {
        url.searchParams.delete('results_by');
      }

      if (value === 'pie') url.searchParams.delete('from');

      url.searchParams.set('chart_type', value);

      if (
        (this.chartType =
          'stack' && value == 'pie' && (this.procurementsTracking || this.carbonFootprints))
      ) {
        url.searchParams.delete('from');
        url.searchParams.delete('to');
      }

      url.searchParams.sort();
      window.location.href = url;
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.chartType = url.searchParams.get('chart_type') || this.defaultParams['chart_type'];
    this.resultsBy = this.defaultParams['results_by'] || url.searchParams.get('results_by');
    this.estate = url.searchParams.get('estate') == 'true';
    this.byScope = url.searchParams.get('by_scope') == 'true';
    this.scope2Comparisson = url.searchParams.get('scope') == 'scope_2_comparisson';
    this.byCategories = url.searchParams.get('by_categories') == 'true';
  },
  components: {
    'inline-svg': InlineSvg,
  },
};
</script>
