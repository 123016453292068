<template>
  <div v-if="report" class="button-filters tracking-type">
    <span>Focus</span>
    <div v-if="byCategories">
      <div
        :class="[
          'icon filter-icon circled tooltip consumption',
          { 'icon-active': selectedCategory == 'all' },
        ]"
        @click="updateCategory('all')"
      >
        <div class="icon-text">All</div>
        <span class="tooltiptext">All categories</span>
      </div>
      <div
        v-for="category in categories"
        :key="category.number"
        :class="[
          'icon filter-icon circled tooltip consumption',
          { 'icon-active': selectedCategory == category.number },
        ]"
        @click="updateCategory(category.number)"
      >
        <div class="icon-text">{{ category.number }}</div>
        <span class="tooltiptext">Category {{ category.number }}</span>
      </div>
    </div>
    <div v-if="!byCategories" class="icon filter-icon circled tooltip consumption icon-active">
      <div class="icon-text">D&I</div>
      <span class="tooltiptext">Direct & Indirect</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    report: {
      type: Boolean,
      default: true,
    },
    byCategories: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      categories: [
        { number: 1 },
        { number: 2 },
        { number: 3 },
        { number: 4 },
        { number: 5 },
        { number: 6 },
      ],
      selectedCategory: 'all',
    };
  },
  beforeMount() {
    const url = new URL(window.location.href);
    this.byCategories = url.searchParams.get('by_categories');
    this.selectedCategory = url.searchParams.get('category') || 'all';
  },
  methods: {
    updateCategory(category) {
      const url = new URL(window.location.href);
      url.searchParams.set('category', category);
      window.location.href = url;
    },
  },
};
</script>
