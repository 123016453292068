import FormController from './form_controller';

export default class extends FormController {
  static values = {
    ids: Array,
  };

  restOutOfScope = (event) => {
    const id = event.detail.id;

    if (this.idsValue.includes(id))
      this.idsValue = this.idsValue.filter((currentId) => currentId !== id);
  };

  sumOutOfScope(event) {
    const id = event.detail.id;

    if (!this.idsValue.includes(id)) this.idsValue = [...this.idsValue, id];
  }

  confirmSubmit(event) {
    const message =
      "We've noticed that you have added data for some of the categories above, some of theese categories are about to become out of scope, all data related to their sub categories will whipe out.";
    if (this.idsValue.length > 0 && !confirm(message)) event.preventDefault();
  }
}
