var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.report)?_c('div',{staticClass:"button-filters tracking-type"},[_c('span',[_vm._v("Focus")]),_vm._v(" "),(_vm.byCategories)?_c('div',[_c('div',{class:[
        'icon filter-icon circled tooltip consumption',
        { 'icon-active': _vm.selectedCategory == 'all' },
      ],on:{"click":function($event){return _vm.updateCategory('all')}}},[_c('div',{staticClass:"icon-text"},[_vm._v("All")]),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("All categories")])]),_vm._v(" "),_vm._l((_vm.categories),function(category){return _c('div',{key:category.number,class:[
        'icon filter-icon circled tooltip consumption',
        { 'icon-active': _vm.selectedCategory == category.number },
      ],on:{"click":function($event){return _vm.updateCategory(category.number)}}},[_c('div',{staticClass:"icon-text"},[_vm._v(_vm._s(category.number))]),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Category "+_vm._s(category.number))])])})],2):_vm._e(),_vm._v(" "),(!_vm.byCategories)?_c('div',{staticClass:"icon filter-icon circled tooltip consumption icon-active"},[_c('div',{staticClass:"icon-text"},[_vm._v("D&I")]),_vm._v(" "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Direct & Indirect")])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }