<template>
  <div v-if="report" class="button-filters tracking-type">
    <div
      v-if="report && fuelGroup != 'footprint' && fuelGroup != 'iso_footprint'"
      :class="['circled tooltip']"
    >
      <span v-if="fuelGroup != 'footprint' && fuelGroup != 'iso_footprint'" class="inline-block"
        >Result</span
      >
      <inline-svg
        :src="require('images/icons/noun_info.svg')"
        class="icon small black-icon"
      ></inline-svg>
      <span class="tooltiptext grouped black">
        <p>Data last updated: {{ this.lastUpdatedReadingDate }}</p>
        <p
          >Chart last updated: {{ this.lastRefreshDate }}
          {{ this.updating == 'true' ? '(Updating...)' : '' }}</p
        >
        <p>Chart not updated? <a href="https://app.piliogroup.com/questions/108">Click here</a></p>
      </span>
    </div>
    <span v-else-if="byScope == 'true'">Focus</span>
    <span v-else>Report</span>
    <div
      v-if="fuelGroup != 'transport' && !carbonFootprints && showOtherOptions"
      :class="[
        'icon filter-icon circled tooltip consumption',
        { 'icon-active': trackingType == 'consumption' },
      ]"
      @click="urlModifier('tracking_type', 'consumption')"
    >
      <inline-svg :src="require('images/icons/consumption.svg')"></inline-svg>
      <span class="tooltiptext">Energy</span>
    </div>

    <div
      v-if="fuelGroup != 'transport' && !carbonFootprints && showOtherOptions"
      :class="['icon filter-icon circled tooltip cost', { 'icon-active': trackingType == 'cost' }]"
      @click="urlModifier('tracking_type', 'cost')"
    >
      <inline-svg :src="require('images/icons/cost.svg')"></inline-svg>
      <span class="tooltiptext">Cost</span>
    </div>

    <div
      v-if="!byScope && fuelGroup != 'environment'"
      :class="[
        'icon filter-icon circled tooltip carbon_market_based',
        {
          'icon-active': offsetting
            ? offsettingReport == 'carbon_market_based'
            : trackingType == 'carbon_market_based',
        },
      ]"
      @click="
        offsetting
          ? urlModifier('offsetting_report', 'carbon_market_based')
          : urlModifier('tracking_type', 'carbon_market_based')
      "
    >
      <inline-svg
        :src="require('images/icons/carbon_market_based.svg')"
        class="big-icon"
      ></inline-svg>
      <span class="tooltiptext">Market-based emissions</span>
    </div>

    <div
      v-if="!byScope && fuelGroup != 'environment'"
      :class="[
        'icon filter-icon circled tooltip carbon',
        { 'icon-active': offsetting ? offsettingReport == 'carbon' : trackingType == 'carbon' },
      ]"
      @click="
        offsetting
          ? urlModifier('offsetting_report', 'carbon')
          : urlModifier('tracking_type', 'carbon')
      "
    >
      <inline-svg
        :src="require('images/icons/carbon_location_based.svg')"
        class="big-icon"
      ></inline-svg>
      <span class="tooltiptext">Location-based emissions</span>
    </div>

    <div
      v-if="offsetting || (carbonFootprints && byScope)"
      :class="[
        'icon filter-icon circled all_scopes tooltip',
        { 'icon-active': offsetting ? offsettingReport == 'all_scopes' : scope == 'all_scopes' },
      ]"
      @click="
        offsetting
          ? urlModifier('offsetting_report', 'all_scopes')
          : urlModifier('scope', 'all_scopes')
      "
    >
      <inline-svg :src="require('images/icons/scope.svg')"></inline-svg>
      <span class="tooltiptext">All scopes</span>
    </div>

    <div
      v-if="offsetting || (carbonFootprints && byScope)"
      :class="[
        'icon filter-icon circled detailed tooltip',
        { 'icon-active': offsetting ? offsettingReport == 'detailed' : scope == 'detailed' },
      ]"
      @click="
        offsetting ? urlModifier('offsetting_report', 'detailed') : urlModifier('scope', 'detailed')
      "
    >
      <inline-svg :src="require('images/icons/binoculars.svg')"></inline-svg>
      <span class="tooltiptext">All scopes (detailed)</span>
    </div>

    <div
      v-if="offsetting || (carbonFootprints && byScope)"
      :class="[
        'icon filter-icon circled scope_one_and_two tooltip',
        {
          'icon-active': offsetting
            ? offsettingReport == 'scope_one_and_two'
            : scope == 'scope_one_and_two',
        },
      ]"
      @click="
        offsetting
          ? urlModifier('offsetting_report', 'scope_one_and_two')
          : urlModifier('scope', 'scope_one_and_two')
      "
    >
      <inline-svg :src="require('images/icons/consumption.svg')"></inline-svg>
      <span class="tooltiptext">Scope 1 and 2</span>
    </div>
    <div
      v-if="offsetting || (carbonFootprints && byScope)"
      :class="[
        'icon filter-icon circled scope_three tooltip',
        { 'icon-active': offsetting ? offsettingReport == 'scope_three' : scope == 'scope_three' },
      ]"
      @click="
        offsetting
          ? urlModifier('offsetting_report', 'scope_three')
          : urlModifier('scope', 'scope_three')
      "
    >
      <inline-svg :src="require('images/icons/scope_three.svg')"></inline-svg>
      <span class="tooltiptext">Scope 3</span>
    </div>
    <div
      v-if="offsetting || (carbonFootprints && byScope)"
      :class="[
        'icon filter-icon circled ghg_protocol tooltip',
        {
          'icon-active': offsetting ? offsettingReport == 'ghg_protocol' : scope == 'ghg_protocol',
        },
      ]"
      @click="
        offsetting
          ? urlModifier('offsetting_report', 'ghg_protocol')
          : urlModifier('scope', 'ghg_protocol')
      "
    >
      <inline-svg :src="require('images/icons/ghg_protocol.svg')"></inline-svg>
      <span class="tooltiptext">Scope 3 GHG Protocol</span>
    </div>
    <div
      v-if="(offsetting || (carbonFootprints && byScope)) && (estate || subgroup) && stackChart"
      :class="[
        'icon filter-icon circled tooltip',
        {
          'icon-active': offsetting
            ? offsettingReport == 'scope_2_comparisson'
            : scope == 'scope_2_comparisson',
        },
      ]"
      @click="
        offsetting
          ? urlModifier('offsetting_report', 'scope_2_comparisson')
          : urlModifier('scope', 'scope_2_comparisson')
      "
    >
      <inline-svg :src="require('images/icons/electricity_factor_types.svg')"></inline-svg>
      <span class="tooltiptext">Electricity location vs market based emissions (Scope 2 only)</span>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  props: {
    report: {
      type: Boolean,
      default: true,
    },
    showOtherOptions: {
      type: Boolean,
      default: true,
    },
    carbonFootprints: {
      type: Boolean,
      default: false,
    },
    byScope: {
      type: Boolean,
      default: false,
    },
    offsetting: {
      type: Boolean,
      default: false,
    },
    scope: String,
    offsettingReport: String,
    defaultParams: Object,
    lastUpdatedReadingDate: String,
    lastRefreshDate: String,
    updating: Boolean,
  },
  data: function () {
    return {
      fuelGroup: null,
      trackingType: null,
    };
  },
  methods: {
    urlModifier: function (parameter, value) {
      let url = new URL(window.location.href);
      url.searchParams.set(parameter, value);
      url.searchParams.sort();
      window.location.href = url;
    },
  },
  beforeMount: function () {
    let url = new URL(window.location.href);
    this.trackingType =
      url.searchParams.get('tracking_type') || this.defaultParams['tracking_type'];
    this.fuelGroup = this.defaultParams['fuel_group'];
    console.log(this.defaultParams);
    console.log(this.trackingType);
    this.byScope = url.searchParams.get('by_scope');
    this.estate = url.searchParams.get('estate') == 'true';
    this.stackChart = url.searchParams.get('chart_type') != 'pie';
    this.subgroup = url.searchParams.get('subgroup');
  },
  components: {
    'inline-svg': InlineSvg,
  },
};
</script>
